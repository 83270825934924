/*
 * @ignore
 * const for gregorian date
 * @author yiminghe@gmail.com
 */

"use strict";

module.exports = {
  /*
   * Enum indicating sunday
   * @type Number
   * @member Date.Gregorian
   */
  SUNDAY: 0,
  /*
   * Enum indicating monday
   * @type Number
   * @member Date.Gregorian
   */
  MONDAY: 1,
  /*
   * Enum indicating tuesday
   * @type Number
   * @member Date.Gregorian
   */
  TUESDAY: 2,
  /*
   * Enum indicating wednesday
   * @type Number
   * @member Date.Gregorian
   */
  WEDNESDAY: 3,
  /*
   * Enum indicating thursday
   * @type Number
   * @member Date.Gregorian
   */
  THURSDAY: 4,
  /*
   * Enum indicating friday
   * @type Number
   * @member Date.Gregorian
   */
  FRIDAY: 5,
  /*
   * Enum indicating saturday
   * @type Number
   * @member Date.Gregorian
   */
  SATURDAY: 6,
  /*
   * Enum indicating january
   * @type Number
   * @member Date.Gregorian
   */
  JANUARY: 0,
  /*
   * Enum indicating february
   * @type Number
   * @member Date.Gregorian
   */
  FEBRUARY: 1,
  /*
   * Enum indicating march
   * @type Number
   * @member Date.Gregorian
   */
  MARCH: 2,
  /*
   * Enum indicating april
   * @type Number
   * @member Date.Gregorian
   */
  APRIL: 3,
  /*
   * Enum indicating may
   * @type Number
   * @member Date.Gregorian
   */
  MAY: 4,
  /*
   * Enum indicating june
   * @type Number
   * @member Date.Gregorian
   */
  JUNE: 5,
  /*
   * Enum indicating july
   * @type Number
   * @member Date.Gregorian
   */
  JULY: 6,
  /*
   * Enum indicating august
   * @type Number
   * @member Date.Gregorian
   */
  AUGUST: 7,
  /*
   * Enum indicating september
   * @type Number
   * @member Date.Gregorian
   */
  SEPTEMBER: 8,
  /*
   * Enum indicating october
   * @type Number
   * @member Date.Gregorian
   */
  OCTOBER: 9,
  /*
   * Enum indicating november
   * @type Number
   * @member Date.Gregorian
   */
  NOVEMBER: 10,
  /*
   * Enum indicating december
   * @type Number
   * @member Date.Gregorian
   */
  DECEMBER: 11
};